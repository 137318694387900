<template>
  <div class="PageList">
    <div class="PageAddBtn">
      <a-button type="primary" @click="PagepowerAdd('', '添加页面')"
        >添加页面</a-button
      >
    </div>
    <a-table
      :columns="columns"
      :data-source="data"
      :rowKey="
        (record, index) => {
          return record.id + record.name;
        }
      "
      :expanded-row-keys.sync="expandedRowKeys"
    >
      <span slot="pSystem" slot-scope="text">
        {{ PSystemList[text] }}
      </span>
      <span slot="bind" slot-scope="text">
        {{ text == 1 ? "是" : text == 2 ? "否" : "" }}
      </span>
      <!-- action -->
      <span slot="action" slot-scope="text, record" class="table_action">
        <a v-if="record.children" @click="PagepowerEdit(record, '页面编辑')"
          >页面编辑</a
        >
        <a-popconfirm
          title="Are you sure delete this task?"
          ok-text="Yes"
          cancel-text="No"
          @confirm="PageDel(record, '页面删除')"
          @cancel="cancel"
        >
          <a v-if="record.children" class="delBTN">页面删除</a>
        </a-popconfirm>
        <!-- <a
          v-if="record.children"
          class="delBTN"
          @click="PagepowerDel(record, '页面删除')"
          >页面删除</a
        > -->
        <a v-if="record.children" @click="PagepowerAdd(record, '接口添加')"
          >接口添加</a
        >
        <a v-if="!record.children" @click="PagepowerEdit(record, '接口编辑')"
          >接口编辑</a
        >
        <a-popconfirm
          title="Are you sure delete this task?"
          ok-text="Yes"
          cancel-text="No"
          @confirm="PagepowerDel(record, '接口删除')"
          @cancel="cancel"
        >
          <a v-if="!record.children" class="delBTN">接口删除</a>
        </a-popconfirm>
        <!-- <a
          v-if="!record.children"
          class="delBTN"
          @click="PagepowerDel(record, '接口删除')"
          >接口删除</a
        > -->
      </span>
    </a-table>

    <a-drawer
      :title="drawer_title"
      placement="right"
      :width="750"
      :closable="false"
      :visible="drawer_visible"
      :after-visible-change="drawer_afterVisibleChange"
      @close="drawer_onClose"
    >
      <!-- 编辑 -->
      <div>
        <div class="inputDiv" v-if="drawer_title == '添加页面'">
          选择系统:
          <a-radio-group v-model="radio_value_XT" @change="radio_onChangeXT">
            <a-radio value="xc">现场管理 </a-radio>
            <a-radio value="jf">交付系统</a-radio>
            <a-radio value="scmcore">供应商</a-radio>
          </a-radio-group>
        </div>
        <div class="inputDiv">
          {{ drawer_title }}名称：
          <a-input placeholder="请输入名称" v-model="PageName" />
        </div>
        <div class="inputDiv">
          {{ drawer_title }}描述：
          <a-input placeholder="请输入描述" v-model="PageDescribe" />
        </div>
        <div
          class="inputDiv"
          v-if="drawer_title == '接口添加' || drawer_title == '接口编辑'"
        >
          是否绑定页面:
          <a-radio-group v-model="radio_value" @change="radio_onChange">
            <a-radio :value="1"> 是 </a-radio>
            <a-radio :value="2"> 否 </a-radio>
          </a-radio-group>
        </div>
        <div class="inputBtns">
          <a-button @click="drawer_onClose">取消</a-button>
          <a-button type="primary" class="InputSubmit" @click="Submit"
            >提交</a-button
          >
        </div>
      </div>
    </a-drawer>
  </div>
</template>
<script>
import {
  GetPagelist,
  AddPage,
  EditPage,
  DelPage,
  AddPagepower,
  EditPagepower,
  DelPagepower,
} from "@/api/role";
const columns = [
  {
    title: "系统名称",
    dataIndex: "pSystem",
    key: "pSystem",
    scopedSlots: { customRender: "pSystem" },
  },
  {
    title: "页面名称",
    dataIndex: "name",
    key: "name",
  },
  {
    title: "页面描述",
    dataIndex: "describe",
    key: "describe",
  },
  {
    title: "接口是否绑定页面",
    dataIndex: "bind",
    key: "bind",
    scopedSlots: { customRender: "bind" },
  },
  {
    title: "操作",
    dataIndex: "action",
    scopedSlots: { customRender: "action" },
  },
];

const data = [];

export default {
  name: "MenuList",

  data() {
    return {
      data,
      columns,
      expandedRowKeys: [],
      drawer_visible: false,
      drawer_title: "",
      PageName: "",
      PageDescribe: "",
      PageId: "",
      radio_value: "",
      radio_value_XT: "",
      record: "",
      queryParam: {
        pSystem: "",
      },
      PSystemList: {
        xc: "现场管理系统",
        jf: "交付系统",
        scmcore: "供应商",
      },
    };
  },
  created() {
    this.GetPageList();
  },
  watch: {
    drawer_visible(newValue) {
      if (newValue == false) {
        this.drawer_title = "";
        this.PageName = "";
        this.PageDescribe = "";
        this.PageId = "";
        this.record = "";
        this.radio_value_XT = "";
        this.radio_value = "";
      }
    },
  },
  methods: {
    // 获取页面信息
    GetPageList() {
      let orderParam = ["pSystem"];
      const param = Object.assign({}, this.queryParam);
      const requestParameters = this.$order(param, orderParam);
      const params = {
        ...requestParameters,
        verify: this.$verify(requestParameters, orderParam),
      };
      return GetPagelist(params).then((res) => {
        this.data = res.data;
      });
    },
    // 编辑
    PagepowerEdit(record, title) {
      this.drawer_visible = true;
      this.drawer_title = title;
      console.log("111", title, record);
      // console.log(this.data.bind);
      this.radio_value = record.bind;
      this.PageName = record.name;
      this.PageDescribe = record.describe;
      this.PageId = record.id;
      this.record = record;
    },
    // 接口添加
    PagepowerAdd(record, title) {
      this.drawer_visible = true;
      this.drawer_title = title;
      console.log(title, record);
      this.PageName = "";
      this.PageDescribe = "";
      this.PageId = record.id;
      this.record = record;
    },
    // 页面删除
    PageDel(record, title) {
      console.log(title, record);

      if (title == "页面删除") {
        let queryParam = {
          id: record.id,
        };
        const orderParam = ["id"];

        const requestParameters = this.$order(queryParam, orderParam);
        const params = {
          ...requestParameters,
          verify: this.$verify(requestParameters, orderParam),
        };
        return DelPage(params).then((res) => {
          console.log(res);
          this.$message.success(res.data);
          this.GetPageList();
        });
      }
    },
    // 页面接口删除
    PagepowerDel(record, title) {
      // this.drawer_visible = true;
      // this.drawer_title = title;
      console.log(title, record);
      // this.PageName = record.name;
      // this.PageDescribe = record.describe;
      // this.PageId = record.id;
      if (title == "接口删除") {
        let queryParam = {
          id: record.id,
          // name: this.PageName,
          // describe: this.PageDescribe,
        };
        const orderParam = ["id"];

        const requestParameters = this.$order(queryParam, orderParam);
        const params = {
          ...requestParameters,
          verify: this.$verify(requestParameters, orderParam),
        };
        return DelPagepower(params).then((res) => {
          console.log(res);
          this.$message.success(res.data);
          this.GetPageList();
        });
      }
    },
    // 弹出框
    drawer_afterVisibleChange(val) {
      // console.log("visible", val);
    },

    drawer_onClose() {
      this.drawer_visible = false;
    },
    // 提交
    Submit() {
      console.log(this.PageName, this.PageDescribe);
      if (this.drawer_title == "页面编辑") {
        let queryParam = {
          id: this.PageId,
          name: this.PageName,
          describe: this.PageDescribe,
          pSystem: this.radio_value_XT,
        };
        const orderParam = ["id", "name", "describe", "pSystem"];

        const requestParameters = this.$order(queryParam, orderParam);
        const params = {
          ...requestParameters,
          verify: this.$verify(requestParameters, orderParam),
        };
        return EditPage(params).then((res) => {
          console.log(res);
          this.$message.success(res.data);
          this.GetPageList();
        });
      } else if (this.drawer_title == "接口编辑") {
        // 需要页面的id 为p_id
        let queryParam = {
          name: this.PageName,
          describe: this.PageDescribe,
          p_id: this.record.p_id,
          id: this.PageId,
          p_bind: this.radio_value,
        };
        const orderParam = ["id", "name", "describe", "p_id", "p_bind"];
        const requestParameters = this.$order(queryParam, orderParam);
        const params = {
          ...requestParameters,
          verify: this.$verify(requestParameters, orderParam),
        };
        return EditPagepower(params).then((res) => {
          this.$message.success(res.data);
          this.GetPageList();
        });
      } else if (this.drawer_title == "接口添加") {
        let queryParam = {
          name: this.PageName,
          describe: this.PageDescribe,
          p_id: this.PageId,
          p_bind: this.radio_value,
        };
        const orderParam = ["name", "describe", "p_id", "p_bind"];

        const requestParameters = this.$order(queryParam, orderParam);
        const params = {
          ...requestParameters,
          verify: this.$verify(requestParameters, orderParam),
        };
        return AddPagepower(params).then((res) => {
          this.$message.success(res.data);
          this.GetPageList();
        });
      } else if (this.drawer_title == "添加页面") {
        let queryParam = {
          name: this.PageName,
          describe: this.PageDescribe,
          pSystem: this.radio_value_XT,
        };
        const orderParam = ["name", "describe", "pSystem"];

        const requestParameters = this.$order(queryParam, orderParam);
        const params = {
          ...requestParameters,
          verify: this.$verify(requestParameters, orderParam),
        };
        return AddPage(params).then((res) => {
          this.$message.success(res.data);
          this.GetPageList();
        });
      }
    },
    // 是否绑定页面
    radio_onChange(e) {
      console.log("radio checked", e.target.value);
    },
    radio_onChangeXT(e) {
      this.radio_value_XT = e.target.value;
      console.log("选择系统", e.target.value);
    },
    confirm(e) {
      console.log(e);
      this.$message.success("Click on Yes");
    },
    cancel(e) {
      console.log(e);
      this.$message.error("Click on No");
    },
  },
};
</script>
<style  lang="less" scoped>
.PageList {
  margin-top: 20px;
}
.table_action {
  a {
    margin: 0 10px;
  }
}
.ant-input {
  width: 50%;
  height: 50px;
}
.inputDiv {
  margin: 10px 0;
  display: flex;
  justify-content: center;
  align-items: center;
}
.inputBtns {
  margin-top: 50px;
  display: flex;
  justify-content: right;
}
.InputSubmit {
  margin: 0 10px;
}
.PageAddBtn {
  display: flex;
  justify-content: left;
}
.delBTN {
  color: red;
}
</style>
